@import "assets/showcase/styles/primeng.css";
@import "../node_modules/primeflex/primeflex.css";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
/* @import "../node_modules/@fullcalendar/common/main.min.css";
@import "../node_modules/@fullcalendar/daygrid/main.min.css";
@import "../node_modules/@fullcalendar/list/main.min.css";
@import "../node_modules/@fullcalendar/timegrid/main.min.css"; */
@import "../node_modules/quill/dist/quill.snow.css";
@import "assets/showcase/styles/flags.css";
@import "assets/showcase/styles/app/app.scss";