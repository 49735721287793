.p-password {
    position: relative;
    display: inline-flex;
}

.p-password-panel {
    position: absolute;
}

.p-password .p-password-panel {
    min-width: 100%;
}

.p-password-meter {
    height: 10px;
}

.p-password-strength {
    height: 100%;
    width: 0%;
    transition: width 1s ease-in-out;
}

.p-fluid .p-password {
    display: flex;
}