.layout-news {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 250px;
    background-image: linear-gradient(to left, #e2821a, #e49e18);

    .layout-news-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-weight: bold;
        font-size: 20px;

        img.layouts-news-mockup-image {
            max-width: 350px;
        }

        .layout-news-button {
            border: 2px solid #212121;
            color: #212121;
            font-size: 15px;
            padding: 0.5em 1em;
            font-weight: bolder;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            transition: background-color 0.15s;
            margin-left: 1rem;
            position: relative;
            z-index: 1;

            &:hover {
                background-color: rgba(255, 255, 255, 0.25);
            }
        }
    }

    .layout-news-close {
        cursor: pointer;
        color: #fff;
        position: absolute;
        z-index: 2;
        right: 28px;
        background-color: rgba(0,0,0,.3);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23px;
        height: 23px;
    }
}