.layout-config {
    position: fixed;
    padding: 0;
    top: 0;
    right: 0;
    display: block;
    width: 550px;
    z-index: 998;
    height: 100%;
    transition: transform .4s cubic-bezier(.05,.74,.2,.99);
    transform: translateX(100%);
    backface-visibility: hidden;

    &.layout-config-active {
        transform: translateX(0);

        .layout-config-content-wrapper {
            .layout-config-button {
                i {
                    transform: rotate(0deg);
                }
            }
        }
    }

    .layout-config-content-wrapper {
        position: relative;
        height: 100%;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24);
        color: var(--text-color);
        background-color: var(--surface-f);

        .layout-config-button {
            display: block;
            position: absolute;
            width: 52px;
            height: 52px;
            line-height: 52px;
            background-color: var(--primary-color);
            text-align: center;
            color: var(--primary-color-text);
            top: 40px;
            left: -51px;
            z-index: -1;
            overflow: hidden;
            cursor: pointer;
            outline: 0 none;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

            i {
                font-size: 26px;
                line-height: inherit;
                cursor: pointer;
                transform: rotate(360deg);
                transition: transform 1s;
            }
        }
    }

    a {
        color: $linkColor;
        font-weight: 600;

        &:hover {
            text-decoration: underline;
        }
    }

    .layout-config-content {
        overflow: auto;
        height: 100%;
        padding: 2rem;
    }

    .config-scale {
        display: flex;
        align-items: center;
        margin: 1rem 0 2rem 0;

        .p-button {
            margin-right: .5rem;
        }

        i {
            margin-right: .5rem;
            font-size: .75rem;
            color: var(--text-color-secondary);

            &.scale-active {
                font-size: 1.25rem;
                color: $linkColor;
            }
        }
    }

    .layout-config-close {
        position: absolute;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        right: 20px;
        top: 20px;
        z-index: 999;
        background-color: var(--primary-color);
        border-radius: 50%;
        cursor: pointer;
        transition: background-color .2s, box-shadow .2s, transform .2s;

        i {
            color:  var(--primary-color-text);
            line-height: inherit;
            font-size: 14px;
        }

        &:hover {
            transform: scale(1.1);
        }

        &:focus {
            @include focused();
        }
    }

    .p-grid > div {
        padding: 1rem;
        text-align: center;

        span {
            display: block;
        }

        button {
            position: relative;
            display: inline-flex;
            justify-content: center;

            &:focus {
                @include focused();
            }
        }
    }

    .free-themes {
        img {
            width: 50px;
            border-radius: 4px;
            transition: transform .2s;

            &:hover {
                transform: scale(1.1);
            }
        }

        span {
            font-size: .875rem;
            margin-top: .25rem;
        }
    }

    .premium-themes {
        img {
            width: 100%;
            transition: transform .2s;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}