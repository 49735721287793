
@media screen and (max-width: 960px) {
    .layout-wrapper {
        .layout-news {
            margin-left: 0;
            .layout-news-button {
                display: none;
            }
        }
    }

    .layout-topbar {
        margin-left: 0;
        height: 110px;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0;

        .menu-button {
            display: block;
        }

        .logo {
            img{
                width: 150px;
            }
        }

        .app-theme {
            margin-left: 0;
            margin-right: 23px;
        }

        .topbar-menu {
            background-color: var(--surface-a);
            width: 100%;
            height: 40px;
            margin: 0;
            border-top: 1px solid var(--surface-d);

            > li {
                height: 40px;
                line-height: 40px;
                width: 25%;

                > a {
                    padding-bottom: 0;
                    height: 40px;
                    line-height: 38px;
                    width: 100%;
                    font-size: 12px;
                    font-weight: 600;
                    min-width: auto;
                }

                &.topbar-submenu > ul {
                    top: 40px;
                }
            }
        }
    }

    
    // when the screen is small
    .layout-content {
        margin-left: 0;

        .content-section {
            &.introduction {
                flex-direction: column;

                .app-inputstyleswitch {
                    margin-top: 1.5rem;
                    margin-left: 0;
                }
            }
        }

        .video-container {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .layout-mask {
        background-color: rgba(0, 0, 0, 0.1);

        &.layout-mask-active {
            z-index: 998;
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
            transition: background-color .5s;
        }
    }

    .home {
        .introduction > div {
            width: 100%;
        }

        .features > div {
            width: 100%;
        }

        .whouses > div {
            width: 100%;
        }

        .prosupport > div {
            width: 100%;
        }
    }

    .layout-config {
        .layout-config-button {
            left: auto;
            right: -52px;
        }

        &.layout-config-active {
            width: 100%;
        }
    }

    .blocked-scroll {
        overflow: hidden;
    }
}

@media screen and (max-width: 640px) {
    .layout-wrapper.layout-news-active {
        .topbar-menu {
            > li {
                &.topbar-submenu {
                    > ul  {
                        top: 180px;
                    }
                }
            }
        }
    }

     .layout-topbar {
        .topbar-menu {
             > li {
                 &.topbar-submenu {
                     position: static;
                     > ul {
                         top: 110px;
                         position: fixed;
                         right: auto;
                         left: 0;
                         width: 100vw;
                     }
                 }
             }
         }
     } 
}
